.notes-split {
    display: flex;
    column-gap: 16px;

    .notes-left {
        flex-grow: 1;
    }

    valumize-notes {
        width: 480px;
    }
}
