@import "variables";

.mat-expansion-panel {
    background: $expansion-panel-grey;
    border: solid 0.5px $light-grey;

    table {
        background-color: $expansion-panel-grey;
    }
}
