@import "variables";

.table-container {
    max-width: 100%;
    overflow: auto;
}

.mat-mdc-header-row {
    height: 40px !important;

    .mat-mdc-header-cell {
        border-bottom: solid 1px $label-grey;

        &.additional-header {
            border-bottom: none;
            vertical-align: bottom;
        }
    }

    th {
        vertical-align: bottom;
        padding-bottom: 8px;
        padding-top: 8px;
        line-height: 1.2;
    }
}

.mat-mdc-row {
    height: 28px !important;

    td {
        vertical-align: top;
        padding-bottom: 8px;
        padding-top: 8px;
        line-height: 1.2;
        border-bottom: solid 1px $light-grey;
    }
}

.row-important {
    td {
        background-color: $important-row-bg;
        font-weight: $font-weight-bold;
    }
}

.row-top-border {
    td {
        border-top: solid 1px $label-grey;
    }
}

.row-bottom-border {
    td {
        border-bottom: solid 1px $label-grey;
    }
}

.column-important {
    border-left: solid 1px $dark-grey;
    border-right: solid 1px $dark-grey;
}

