/* Detail Card */
.detail-card {
    flex-grow: 1;
    margin-top: 16px;
    border-width: 0 !important;
    border-radius: 8px !important;
    padding: 24px;
    box-shadow: 0 1px 4px -1px #0001;

}

.detail-card-content {
    padding-top: 16px !important;
    padding-bottom: 16px !important;
}

.detail-card-content-no-title {
    padding-top: 48px !important;
    padding-bottom: 16px !important;
}

.detail-card-actions {
    position: absolute;
    right: 0;
}

/* key-value-pair Section */
.key-value-pair-section {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-bottom: 20px;
}

.key-value-pair-column {
    display: flex;
    flex-direction: column;
    max-width: 500px;
    flex: 1;
    margin-right: 2%;
}

.key-value-pair-column:last-child {
    margin-right: 0;
}

.key-value-pair {
    display: flex;
    flex: 1;
    border-bottom: 1px solid #aaa;
    line-height: 30px;
}

.key-value-item {
    flex: 1;
}

.key-value-item:first-child {
    margin-right: 5px;
    color: $dark-grey;
}

.key-value-item:nth-child(n+2) {
    flex: 1;
    font-weight: $font-weight-bold;
    text-align: end;
}



