/* You can add global styles to this file, and also import other style files */

@import "nav";
@import "cards";
@import "chip";
@import "forms";
@import "tables";
@import "types";
@import "variables";
@import "notes";
@import "expansion-panel";
@import "charts";

mat-action-list a {
    text-decoration: none;
}

.mat-drawer-container {
    background-color: #f3f3f3 !important;
}

.page-content {
    height: calc(100vh - 110px);
    margin-top: 8px;
    margin-left: 48px;
    padding-right: 32px;
    overflow: auto;
}

.breadcrumb {
    margin-top: 24px;
    margin-left: 48px;
    display: flex;
    align-items: center;

    mat-icon {
        margin: 0 8px;
    }

    a {
        color: $label-grey !important;
        text-decoration: none;
    }
}

.last-modified {
    font-size: 12px;
    color: $label-grey;
}

.component-wrapper {
    display: flex;
    gap: 16px;

    .component {
        display: flex;
        flex-grow: 1;
    }
}

valumize-search {
    .search-results-box {
        position: fixed !important;
    }
}

.required-asterisk:after {
    content: "*";
    margin-left: .25rem;
}

.custom-tooltip {
    white-space: pre-line;
}
