@import "variables";

/* TODO(mdc-migration): The following rule targets internal classes of chips that may no longer apply for the MDC version. */
mat-chip {
    margin-top: 5px !important;
    height: 20px !important;
}
/* TODO(mdc-migration): The following rule targets internal classes of chips that may no longer apply for the MDC version. */
mat-chip.low {
    background-color: $danger-bg !important;
}
/* TODO(mdc-migration): The following rule targets internal classes of chips that may no longer apply for the MDC version. */
mat-chip.medium {
    background-color: $warning-bg !important;
}
/* TODO(mdc-migration): The following rule targets internal classes of chips that may no longer apply for the MDC version. */
mat-chip.high {
    background-color: $success-bg !important;
}

.chip-same-value {
    background-color: #cccccc !important;
}

.chip-overwritten-value {
    background-color: #81ef81 !important;
}

.chip-diff-value {
    background-color: #ffff8b !important;
}
