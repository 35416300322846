@import "variables";

mat-sidenav {
    border-right: none !important;
    box-shadow: none !important;
    width: 250px;
}

.mat-drawer-side {
    border: none;
}

.sidenav-info {
    display: flex;
    margin: 24px;

    .logo {
        max-height: 50px;
        max-width: 180px;
    }

    .report-date {
        margin-top: 12px;
    }
}

.mat-mdc-list-item {
    padding: 0 24px !important;
    height: 40px !important;

    .mdc-list-item__primary-text {
        font-size: 14px;
        color: $label-grey;
    }
}

.mat-divider {
    border-top-color: $light-grey;
}

.top-right-nav-toggle {
    float: right;
}

.top-left-nav-toggle {
    float: left;
}
