$valumize-primary: #4949CB;

$success-bg: #B6F2B1;
$warning-bg: #FDDEB9;
$danger-bg: #FFD1D1;
$important-row-bg: #F0F0FF;
$label-grey: #909090;
$dark-grey: #717171;
$expansion-panel-grey: #FBFBFB;
$disabled-grey: #00000061;
$light-grey: #EEEEEE;
$body-color: #202020;
$error-text: #FF0000;

$a-color: #4949CB;
$body-color: #202020;

$font-weight-bold: 500;

$h1-font-size: 28px;
$h2-font-size: 24px;
$h3-font-size: 18px;
$p-font-size: 16px;
$label-font-size: 14px;

// notes
$note-bg: #E6EDFF;
$note-bg-hover: #9BB4FA;
$note-header-text: #4563B9;
$note-icon-hover: darken($note-header-text, 20%);
