@import "variables";

html, body {
    height: 100%;
    min-width: 1024px
}

body {
    color: $body-color !important;
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
}

h1, .h1 {
    font-size: $h1-font-size !important;
}

h2, .h2 {
    font-size: $h2-font-size !important;
}

h3, .h3 {
    font-size: $h3-font-size !important;
    font-weight: $font-weight-bold !important;
    margin-top: 16px !important;
    margin-bottom: 0 !important;
}

p, .p {
    font-size: $p-font-size !important;
}

a, .a {
    color: $a-color !important;
}

label {
    color: $label-grey !important;
}
