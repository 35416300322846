.mat-form-field-disabled {
    .mdc-text-field--filled {
        background-color: white;

        .mat-mdc-input-element:disabled {
            resize: none;
            color: black;
        }
    }

    mat-hint, .mdc-line-ripple, .mat-mdc-form-field-icon-suffix {
        display: none;
    }
}

form {
    display: flex;
    flex-grow: 1;
}

input.ng-invalid.ng-touched {
    border-color: red;
}

.form-error-message {
    color: $error-text;
}

.input-number-narrow {
    width: 100px;
}

.input-date-narrow {
    width: 160px;
}

.input-date-narrow2 {
    width: 80px;
}
